import * as React from "react";
import { graphql } from "gatsby";
import ProductInfo from "../components/page-components/Products/ProductInfo/ProductInfoSamplePack";
import ProductImageSlider from "../components/page-components/Products/ProductImageSlider";
import RelatedProductsSlider from "../components/page-components/Products/RelatedProductsSlider";
import ProductKeyFeatures from "../components/page-components/Products/ProductKeyFeatures";
import { Styling } from "../styles/templates/product-merch.styled";
import Seo from "../components/global/seo";

// markup
const ProductPage = ({ data, pageContext }) => {
  const product = data.wpProduct.acf_product_info.samplePacks;
  const category = "samplePack";
  return (
    <Styling>
      <Seo
        title={data.wpProduct.seo.title}
        description={data.wpProduct.seo.metaDesc}
      />
      <ProductImageSlider images={product.images} />
      <ProductInfo
        context={pageContext}
        product={product}
        genre={data.wpProduct.samplePackCategories.nodes[0].name}
        category={category}
        title={data.wpProduct.title}
        databaseId={data.wpProduct.databaseId}
        snipcartGuid={product.snipcartGuid}
      />
      <ProductKeyFeatures
        context={pageContext}
        product={product}
        category={category}
        title={data.wpProduct.title}
        video={product.video}
        features={product.features}
        databaseId={data.wpProduct.databaseId}
        snipcartGuid={product.snipcartGuid}
        index={1}
      />
      <RelatedProductsSlider products={data.relatedProducts.nodes} />
    </Styling>
  );
};

export default ProductPage;

export const query = graphql`
  query ($slug: String!, $id: String!, $baseTitle: String!) {
    relatedProducts: allWpProduct(
      limit: 10
      filter: {
        id: { ne: $id }
        productCategories: {
          nodes: { elemMatch: { name: { eq: $baseTitle } } }
        }
      }
    ) {
      nodes {
        id
        title
        slug
        acf_product_info {
          samplePacks {
            price
            images {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
    wpProduct(slug: { eq: $slug }) {
      title
      seo {
        title
        metaDesc
      }
      id
      databaseId
      slug
      samplePackCategories {
        nodes {
          name
        }
      }
      acf_product_info {
        samplePacks {
          accordion {
            title
            text
          }
          snipcartGuid
          video
          features {
            feature
          }
          salePrice
          price
          bpmRange
          fileSize
          audio {
            fileName
            file {
              localFile {
                publicURL
              }
            }
          }
          description
          briefProductDescription
          images {
            image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 1500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;
