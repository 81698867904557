import React, { useState, useEffect, useRef } from "react";
import { Styling } from "./styled.js";
import { AiOutlinePlus } from "react-icons/ai";
import AudioPlayer from "../../../reusable/AudioPlayer";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

export default function ProductInfo({
  context,
  product,
  category,
  genre,
  title,
  databaseId,
  snipcartGuid,
}) {
  const {
    accordion,
    description,
    briefProductDescription,
    price,
    images,
    salePrice,
    bpmRange,
    fileSize,
  } = product;

  return (
    <Styling>
      <div className="price-wrapper">
        <span className={salePrice ? `on-sale price` : "price"}>
          £{price.toFixed(2)}
        </span>
        {salePrice ? (
          <span className="sale_price">£{salePrice.toFixed(2)}</span>
        ) : (
          ""
        )}
      </div>
      <div className="main_title reduce_margin">{title}</div>
      <div className="tags">
        {genre ? <div className="tag">{genre}</div> : ""}
        {bpmRange ? <div className="tag">{bpmRange}</div> : ""}
        {fileSize ? <div className="tag">{fileSize}</div> : ""}
      </div>
      <div className="description">{description}</div>

      <AudioPlayer audioFile={product.audio} />

      <button
        class="snipcart-add-item capsule_button black"
        data-item-id={`snipcart-${databaseId}`}
        data-item-price={salePrice ? salePrice.toFixed(2) : price.toFixed(2)}
        data-item-url={process.env.GATSBY_FRONTEND_URL + "/shop" + context.link}
        data-item-description={briefProductDescription}
        data-item-image={images[0]?.image?.localFile?.publicURL}
        data-item-name={title}
        data-item-quantity={1}
        data-item-max-quantity={1}
        data-item-categories={category}
        data-item-metadata={`{"trackQuantities": false, "isPlugin": false}`}
        data-item-file-guid={snipcartGuid}
      >
        Add to cart - £{salePrice ? salePrice.toFixed(2) : price.toFixed(2)}
      </button>

      <Accordion allowMultipleExpanded allowZeroExpanded>
        {accordion.map((accordianItem, index) => {
          return (
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AiOutlinePlus />
                  {accordianItem.title}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>{accordianItem.text}</AccordionItemPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </Styling>
  );
}
